<template>
  <div>
    <Card>
      <template #header>Kira Kayıtları</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addKira = true"
              >Yeni</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Kira Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0" @click="updateValue(item)">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
      <template v-slot:item.phone="{ item }">
        <p class="text-dark">{{ item.tenant_phone }}</p>
      </template>
    </List>
    <b-modal v-model="addKira" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i>Yeni Ekspertiz Kayıt Ekleme</h4>
      </template>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="form-group">
            <label for="">Sözleşme Tarihi</label>
            <input
              type="date"
              class="form-control"
              v-model="date_of_contract"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Sözleşme Süresi </label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="contract_term"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Ödeme Zamanı</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="payment_time"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kiralama Bedeli</label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="renting_price"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kira Başlangıç Bedeli </label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="rental_starting_price"
            >
            </textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kiracı Ad Soyad / Ünvan </label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="tenant_name_surnam_title"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kiracı Telefon </label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="tenant_phone"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kiracı TV/VKN </label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="tenant_tv_vkn"
            ></textarea>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="">Açıklama </label>
            <textarea
              name=""
              id=""
              rows="4"
              class="form-control"
              v-model="explanation"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Kullanıcı</label>
            <textarea name="" id="" rows="1" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <b-form-checkbox name="check-button" switch v-model="is_it_paid">
              Ödendi mi?
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Ödendiği Tarih</label>
            <input type="date" class="form-control" v-model="paid_date" />
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addKira = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="save" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation
    :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
    modalid="kira" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "systemUsers",
  data() {
    return {
      addKira: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Sözleşme Tarihi", value: "date_of_contract" },
        { text: "Sözleşme Süresi", value: "contract_term" },
        { text: "Ödeme Zamanı", value: "payment_time" },
        { text: "Kira Bedeli", value: "renting_price" },
        { text: "Kiracı Ünvan", value: "tenant_name_surnam_title" },
        { text: "Kiracı Telefon", value: "phone" },
        { text: "Kiracı TC/VKN", value: "tenant_tv_vkn" },

        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",

      date_of_contract: "",
      contract_term: "",
      payment_time: "",
      renting_price: "",
      rental_starting_price: "",
      tenant_name_surnam_title: "",
      tenant_phone: "",
      tenant_tv_vkn: "",
      explanation: "",
      is_it_paid: "",
      paid_date: "",


      updateModal :false,
      rent_id : "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    save() {
      let data = {
        date_of_contract: moment(this.date_of_contract).format("DD/MM/YYYY"),
        contract_term: this.contract_term,
        payment_time: this.payment_time,
        renting_price: this.renting_price,
        rental_starting_price: this.rental_starting_price,
        tenant_name_surnam_title: this.tenant_name_surnam_title,
        tenant_phone: this.tenant_phone,
        tenant_tv_vkn: this.tenant_tv_vkn,
        explanation: this.explanation,
        is_it_paid: this.is_it_paid,
        paid_date: moment(this.paid_date).format("DD/MM/YYYY"),
        user_token: this.myUser,
        property_id: this.$route.params.id,
      };
      this.$ApiService
        .post("property/rent", data)
        .then((res) => {
          this.$generateNotification(this, "success", "Kira Eklenmiştir.");
          this.getList();
          this.addKira = false;
        })
        .catch((err) => {
          console.log(err);
          this.$generateNotification(this, "error", "Kira Eklenemedi.");
        });
    },
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/rent?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&property_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          this.items = res.response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(item) { 
      this.$bvModal.show('modal-deleteOperationkira');
      this.deleteOperationUrl = `property/rent`;
      this.deleteIds = `?user_token=${this.myUser}&rent_id=${item._id.$oid}&property_id=${this.$route.params.id}`;
    },
    updateValue(item) {
      this.date_of_contract = moment(item.date_of_contract, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      this.payment_time = item.payment_time;
      this.renting_price = item.renting_price;
      this.rental_starting_price = item.rental_starting_price;
      this.tenant_name_surnam_title = item.tenant_name_surnam_title;
      this.tenant_phone = item.tenant_phone;
      this.tenant_tv_vkn = item.tenant_tv_vkn;
      this.explanation = item.explanation;
      this.is_it_paid = item.is_it_paid;
      this.paid_date = moment(item.paid_date, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
        
     
    this.addKira = true
    this.updateModal = true

    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
